// @ts-nocheck
import { writable, derived } from "svelte/store";

export const apiData = writable({});
export const apiResponse = writable({});
export const openModal = writable(false);

function storable(data) {
    const store = writable(data);
    const { subscribe, set, update } = store;
    const isBrowser = typeof window !== 'undefined';
 
    isBrowser &&
       localStorage.storable &&
       set(JSON.parse(localStorage.storable));
 
    return {
       subscribe,
       set: n => {
          isBrowser() && (localStorage.storable = JSON.stringify(n));
          set(n);
       },
       update: cb => {
          const updatedStore = cb(get(store));
 
          isBrowser() && (localStorage.storable = JSON.stringify(updatedStore));
          set(updatedStore);
       }
    };
 }

export const collapseData = storable(false);
