
import { apiData } from "./store";
import { get } from "svelte/store";

async function sendPost() {
  const res = await fetch('https://app.tbcla.com/all_info/',
  {method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  credentials: 'include',
  // no-cors,
  mode: 'cors',
  body: JSON.stringify({
    "users": true,
    "orders": true,
    "tracking_links": true
  }),
}
  );
  if (res.status === 403) {
    if (window.location.href.includes('orders')||window.location.href.includes('users')) {
      // window.location.href = '/login';
    }
  }


  const data = await res.json();
  if (!res.ok) {
    // throw Error('Status code is ' + res.status);
  }




  if (JSON.stringify(get(apiData)) === JSON.stringify(data)) {
    return;
  }
  apiData.update((apidata) => {
    return { ...apidata, ...data };
  });
  return;
}




async function pollAPI() {
  sendPost();
  setInterval(() => {
    // make API call here
    sendPost();
  }, 15000);
}




export default pollAPI;



